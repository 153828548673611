<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-xl font-bold">View Transaction Monitoring Portal</h1>
      <div class="flex my-3">
        <div class="">
          <button
            class="bg-gray-800 py-3 px-3 rounded-md  text-white"
            @click="
              $router.push({
                name: 'TransactionMontoringPortal',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="flex flex-wrap xl:-mx-4  pb-5">
        <div class="w-1/2 xl:my-4 xl:px-4">
          <div class="w-full p-5 border-2 border-gray-100 bg-white rounded-lg">
            <h4 class="text-blue-900 text-lg font-semibold">
              Information
            </h4>
            <h4
              class="text-right text-lg font-semibold"
              :class="
                transaction.responseCode == '00'
                  ? 'text-green-700'
                  : 'text-black'
              "
            >
              {{
                transaction.responseCode == "99"
                  ? "Internal Error"
                  : transaction.responseCode === "100"
                  ? "Pending"
                  : transaction.responseCode === "101"
                  ? "Failed"
                  : transaction.responseCode === "102"
                  ? "Pending"
                  : transaction.responseCode === "103"
                  ? "Reversal"
                  : transaction.responseCode === "00"
                  ? "Success"
                  : "N/A"
              }}
            </h4>
            <div class="flex flex-wrap xl:-mx-4  pb-5">
              <div class="w-1/2 xl:my-4 xl:px-4">
                <div class="w-full">
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Terminal ID
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      RRN
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      STAN
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Time stamp
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-1/2 xl:my-4 xl:px-4">
                <div class="w-full">
                  <div class="my-3">
                    <p class="text-sm">
                      {{ transaction.terminal_id }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm">
                      {{ transaction.rrn }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm">
                      {{ transaction.stan }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm ">
                      {{ transaction.time }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 bg-white xl:my-4 xl:px-4">
          <div class="w-full border-2 p-3 border-gray-100 bg-white rounded-lg">
            <h4 class="text-blue-900 text-lg font-semibold">
              Information
            </h4>
            <div class="flex flex-wrap xl:-mx-4  pb-5">
              <div class="w-1/2 xl:my-4 xl:px-4">
                <div class="w-full">
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Card PAN
                    </p>
                    <p class="text-sm">
                      {{ transaction.pan }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Card Expiry
                    </p>
                    <p class="text-sm">
                      {{ transaction.card_expiry }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Response Code
                    </p>
                    <p class="text-sm">
                      {{ transaction.responseCode }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-1/2 xl:my-4 xl:px-4">
                <div class="w-full">
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Card Type
                    </p>
                    <p class="text-sm">
                      {{ transaction.cardType }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Authorization Code
                    </p>
                    <p class="text-sm">
                      {{ transaction.authCode }}
                    </p>
                  </div>
                  <div class="my-3">
                    <p class="text-sm font-bold">
                      Transmission Time
                    </p>
                    <p class="text-sm">
                      {{ transaction.transmissionTime }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" flex justify-end items-end">
        <button
          @click="generateReceipt"
          class="py-3 px-6 bg-blue-800 mr-3 rounded-md font-semibold text-white text-sm"
        >
          Generate Reciept (PDF)
        </button>
        <button
          @click="generateReceiptJPG"
          class="py-3 px-6 bg-green-800 rounded-md font-semibold text-white text-sm"
        >
          Generate Reciept (JPG)
        </button>
      </div>

      <div class="w-full shadow-sm p-10">
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Terminal Receipt"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a5"
          pdf-orientation="portrait"
          pdf-content-width="350px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
          :html-to-pdf-options="htmlToPdfOptions"
        >
          <section slot="pdf-content">
            <div
              style="max-width:360px;padding: 10px 20px"
              class="receipt"
              ref="printcontent"
            >
              <img class="bank-logo" :src="receiptDetails.bankLogo" />

              <h5 class="text-center text-600 mb-3">
                {{ receiptDetails.businessName }}
              </h5>
              <p class="text-xs uppercase">Merchant Name:</p>
              <p>TRACTION APPS LTD LA LANG</p>
              <p class="text-xl">
                TERMINAL ID : {{ transaction.transacting_tid }}
              </p>
              <p class="text-center text-xl text-600 py-2">PURCHASE</p>
              <p class="text-sm">STAN: {{ transaction.stan }}</p>
              <p class="text-sm">
                DATE/TIME :
                {{ moment(transaction.time).format("DD MMM YYYY - h:mm:ss a") }}
              </p>
              <h5 class="text-md text-600 py-2">
                AMOUNT: NGN{{ transaction.amount }}
              </h5>
              <p class="text-sm">{{ cardlabel }}</p>
              <h5 class="text-md text-center py-2">{{ transaction.pan }}</h5>
              <p class="text-sm">
                EXPIRY DATE:
                <span class="font-bold">{{ transaction.card_expiry }}</span>
              </p>
              <p class="text-center text-md text-600 py-2">
                {{
                  transaction.responseCode == "00"
                    ? "TRANSACTION APPROVED"
                    : "TRANSACTION FAILED"
                }}
              </p>
              <p class="text-sm">
                Response Code: {{ transaction.responseCode }}
              </p>
              <p class="text-sm">Transaction ID: {{ transactionID }}</p>
              <p class="text-sm">RRN: {{ transaction.rrn }}</p>
              <p class="text-xs text-center pt-5">Powered by Traction</p>
              <p class="text-xs text-center pb-5">www.tractionapps.co</p>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import html2canvas from "html2canvas";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      moment,
      isLoading: false,
      rejectModal: false,
      search: "",
      VueHtml2pdf,
      transaction: {
        terminal_id: null,
        rrn: null,
        stan: null,
        time: null,
        pan: null,
        card_expiry: null,
        responseCode: null,
        cardType: null,
        authCode: null,
        transmissionTime: null,
        cardHolder: null,
        amount: null,
        receipt_type: null,
        cardlabel: null,
      },
      cardlabel: "",
      transactionID: "",
      receiptDetails: {
        terminalName: null,
        bankLogo: null,
        bankName: null,
        businessName: null,
      },
    };
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: `Transaction Receipt`,

        image: {
          type: "jpg",
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          dpi: 199,
          letterRendering: true,
          useCORS: true,
          allowTaint: true,
        },

        jsPDF: {
          unit: "pt",
          format: "a5",
          orientation: "portrait",
        },
      };
    },
    // async bankLogo() {
    //   const logo = await toDataUrl(this.receiptDetails.bankLogo);
    //   return logo;
    // },
  },
  methods: {
    async generateReceipt() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress() {
      // this.isLoading = true;
    },
    hasStartedGeneration() {
      // this.isLoading = false;
    },
    async fetchReceiptDetails() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_SINGLE_MONITORING", {
        id: this.$route.params.id,
      });

      if (res.status == true) {
        this.isLoading = false;
        console.log(res.data.paginationTMList.items[0].responsecode);

        this.transaction.terminal_id =
          res.data.paginationTMList.items[0].terminalid !== ""
            ? res.data.paginationTMList.items[0].terminalid
            : "N/A";
        this.transaction.rrn =
          res.data.paginationTMList.items[0].rrn !== ""
            ? res.data.paginationTMList.items[0].rrn
            : "";
        this.transaction.stan =
          res.data.paginationTMList.items[0].stan !== ""
            ? res.data.paginationTMList.items[0].stan
            : "N/A";
        this.transaction.time =
          res.data.paginationTMList.items[0].created_at !== ""
            ? res.data.paginationTMList.items[0].created_at
            : "N/A";
        this.transaction.pan =
          res.data.paginationTMList.items[0].maskedpan !== ""
            ? res.data.paginationTMList.items[0].maskedpan
            : "N/A";
        this.transaction.card_expiry =
          res.data.paginationTMList.items[0].cardexpiry !== ""
            ? res.data.paginationTMList.items[0].cardexpiry
            : "N/A";
        this.transaction.responseCode =
          res.data.paginationTMList.items[0].responsecode !== ""
            ? res.data.paginationTMList.items[0].responsecode
            : "N/A";
        this.transaction.cardType =
          res.data.paginationTMList.items[0].cardLabel !== ""
            ? res.data.paginationTMList.items[0].cardLabel
            : "N/A";
        this.transaction.authCode =
          res.data.paginationTMList.items[0].authcode !== ""
            ? res.data.paginationTMList.items[0].authcode
            : "N/A";
        this.transaction.transmissionTime =
          res.data.paginationTMList.items[0].transmission_date_time !== ""
            ? res.data.paginationTMList.items[0].transmission_date_time
            : "N/A";
        this.transaction.cardHolder =
          res.data.paginationTMList.items[0].cardHolder !== ""
            ? res.data.paginationTMList.items[0].cardHolder
            : "N/A";
        this.transaction.transactionType =
          res.data.paginationTMList.items[0].transactionType !== ""
            ? res.data.paginationTMList.items[0].transactionType
            : "N/A";
        this.transaction.amount =
          res.data.paginationTMList.items[0].amount !== ""
            ? res.data.paginationTMList.items[0].amount
            : "N/A";
        this.transaction.transacting_tid =
          res.data.paginationTMList.items[0].amount !== ""
            ? res.data.paginationTMList.items[0].transactingTerminalid
            : "N/A";

        this.transaction.cardlabel =
          res.data.paginationTMList.items[0].cardlabel !== ""
            ? res.data.paginationTMList.items[0].cardlabel
            : "N/A";

        let receipt_res = await this.$store.dispatch("FETCH_RECEIPT_DETAILS", {
          terminal_id: this.transaction.terminal_id,
          transacting_tid: this.transaction.transacting_tid,
        });

        if (receipt_res.status) {
          this.receiptDetails.terminalName =
            receipt_res.data.terminalName !== ""
              ? receipt_res.data.terminalName
              : "N/A";
          this.receiptDetails.bankLogo =
            receipt_res.data.transactingTid.bankLogo !== ""
              ? receipt_res.data.transactingTid.bankLogo
              : "N/A";
          this.receiptDetails.bankName =
            receipt_res.data.transactingTid.bankName !== ""
              ? receipt_res.data.transactingTid.bankName
              : "N/A";
          this.receiptDetails.businessName =
            receipt_res.data.transactingTid.businessName !== ""
              ? receipt_res.data.transactingTid.businessName
              : "N/A";
        }
      }
    },
    async toDataUrl(url, callback) {
      //Convert to base64
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },

    async fetchReceiptDetailsNew() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "http://3.90.190.33:8080/v1/transaction/fetch?terminalId=TAZBTRJ1&terminaltype=Android&stan=160255",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.cardlabel = res[0].cardlabel !== "" ? res[0].cardlabel : "N/A";
          this.transactionID =
            res[0].transactionId !== "" ? res[0].transactionId : "N/A";
          console.log(this.transaction.cardlabel);
        })
        .catch((error) => console.log("error", error));
    },

    async fetchBankDetails() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_SINGLE_MONITORING", {
        id: this.$route.params.id,
      });

      if (res.status == true) {
      }
    },
    async generateReceiptJPG() {
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
        useCORS: true,
        allowTaint: true,
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },
  },
  async mounted() {
    this.fetchReceiptDetails();
    this.fetchReceiptDetailsNew();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap");
.receipt p {
  font-family: "Courier Prime", monospace;
}

.receipt h5 {
  font-family: "Courier Prime", monospace;
}
.text-center {
  text-align: center;
}
.bank-logo {
  width: 75px;
  height: 75px;
  margin: 0px auto;
  display: block;
  filter: grayscale(100%);
}
.text-sm {
  font-size: 0.8rem;
}
.text-md {
  font-size: 1rem;
}
.text-xs {
  font-size: 0.6rem;
}
.text-600 {
  font-weight: bold;
}
</style>
